import React from 'react'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'

const SpecializationBox = (props) => (

    <div className='specialization-page__item item wow fadeInUp'>

        <Link className="item__link" to={props.href}>
            {props.icon.localFile.publicURL && <img
                type="icon"
                src={props.icon.localFile.publicURL}
                className="item__icon"
                alt=''/>
}
            <h3>{props.title}</h3>

            {props.slogan !== ''
                ? <p>{props.slogan}</p>
                : <p>{props.zajawka}</p>}

            <p>Czytaj więcej</p>
        </Link>
    </div>

)
SpecializationBox.propTypes = {
    href: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    slogan: PropTypes.string,
    zajawka: PropTypes.string.isRequired
  }

export default SpecializationBox