import React, {Component} from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import {graphql} from "gatsby"
import SpecializationBox from '../components/specialization-box'
import AnimateComponent from '../components/animate-component'

class SpecializationsTemplate extends Component {

    render() {

        const specs = this.props.data.allWordpressWpSpecjalizacje.edges;
        return (

            <Layout>
                <SEO title="Specjalizacje | Świecki Kancelaria"/>
                <AnimateComponent styleName="specialization-page" tag='section'>
                    <div className="specialization-page__container">
                        <header className="specialization-page__header wow zoomIn">
                            <div className="specialization-page__title-container">
                                <h1>
                                    Specjalizacja
                                </h1>
                                <h2>
                                    Specjalizacja jest naszą mocną stroną
                                </h2>
                            </div>
                            <div className="specialization-page__text-block">
                                <p>
                                    Świadcząc usługi prawne swoje działania dostosowujemy do branży i specyfiki
                                    obrotu gospodarczego.
                                </p>
                                <p>
                                    Z powodzeniem łączymy wiedzę prawniczą z wyczuciem biznesowym.
                                </p>
                            </div>
                        </header>
                        <div className="specialization-page__items">
                            {specs.map(({node}) => (
                            <SpecializationBox
                                key={node.id}
                                href={node.slug}
                                title={node.title}
                                slogan={node.acf.slogan}
                                zajawka={node.acf.zajawka}
                                icon={node.featured_media}
                                />
                                ))}
                        </div>
                    </div>
                </AnimateComponent>
            </Layout>
        )
    }
}

export default SpecializationsTemplate

export const query = graphql `
  query{
    allWordpressWpSpecjalizacje( sort: {fields: [menu_order], order: ASC} ) {
        edges {
          node {
            title
            slug
            id
            featured_media{
                localFile {
                    publicURL
                }
            }
            acf{
              slogan
              zajawka
              zalety {
                  tresc
              }
              tytul_zalet
              opis_zalet
              zdjecie {
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 594, quality: 90) {
                              src
                          }
                      }
                  },
              }
              }
          }
        }
      }
  }
`
